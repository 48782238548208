@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-image: url("./statics/background.webp");
    background-size: cover;
    backdrop-filter: saturate(.5);
    background-repeat: repeat-y;
    min-height: 100vh;
}

#slide-container{
    transition: all .5s;
}

.burger-menu{
    position: absolute;
    top: -100vh;
    z-index: -1;
    left: 0;
    width: 100%;
    background-color: #f5a21bdd;
    padding-top: 10px;
    text-align: center;
    transition: all .5s;
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    backdrop-filter: blur(3px);
}
.burger-menu > * {
    padding: 15px;
}

.burger-menu.displayed{
    top: calc(100%);
    transition: all .5s;
}

#menuButton{
    outline: none;
}
